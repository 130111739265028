import React from "react";
import classNames from "classnames";
import "./_styles/Image.scss";

const Image = (props) => {
	const { src, caption, align, alt } = props;
	return (
		<figcaption
			className={classNames(["image", align && "image-align-" + align])}
		>
			<img src={src} alt={alt} />
			{caption && <figcaption>{caption}</figcaption>}
		</figcaption>
	);
};

export default Image;
